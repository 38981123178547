import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function TotalCard(props) {
  const classes = useStyles();

  return (
      <Box className={classes.root}>
          <Typography className={classes.title}>{props.title}</Typography>
          <Typography className={classes.value}>{props.value}</Typography>
      </Box>
  );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  title: {
      fontSize: "1.7",
      fontWeight: 700,
      color: "#2D2D2D",
      marginLeft: "5%",
      marginTop: "5%"
  },
  value: {
      fontSize: "5.1vh",
      fontWeight: 700,
      color: "#4331D7",
      margin: "auto",
  }
}));
