import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function BasicCard(props) {
  const classes = useStyles();

  return (
      <Box className={classes.root}>
          {props.icon}
          <Box className={classes.itemsRight}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.value} style={{ color: props.color}}>{props.value}</Typography>
          </Box>
      </Box>
  );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexBasis: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  itemsRight: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(2)
  },
  title: {
     fontSize: "1.7vh",
     fontWeight: 700,
     color: "#2D2D2D",
     [theme.breakpoints.down("xs")]: {
      fontSize: 12
  }
  },
  value: {
      fontSize: "4.1vh",
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: 30
    }
  }
}));
