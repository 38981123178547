import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./LoginForm";

export default function Login(props) {
  const classes = useStyles();

  return (
      <Box className={classes.root}>
          <Box className={classes.overlay}></Box>
          <LoginForm />
      </Box>
  );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
  root: {
      width: "100vw",
      height: "100vh",
      background: 'url("./login_background.png")',
      position: "relative",
      backgroundSize: "100%"
  },
  overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#4068DF",
      opacity: 0.85,
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  }
}));
