import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";
import CustomTable from "./CustomTable";
import {
    Box,
    MenuItem,
    Select,
    Button,
    CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUsersPagination } from "../../helper/externalCalls";
import { transformUserData } from "../../helper/transformData";
import { checkLoggedIn } from "../../helper/helper";

const PER_PAGE = 7;

const roleItems = [
    {
        id: "Rollen",
        value: "Roles",
    },
    {
        id: "Benutzer",
        value: "User",
    },
    {
        id: "Berater",
        value: "Salesman",
    },
];

const dateRegisteredItems = [
    {
        id: "Zuletzt registriert",
        value: "descending",
    },
    {
        id: "Zuerst registriert",
        value: "ascending"
    }
];

const categoryItems = [
    {
        id: "Kategorie",
        value: "Category",
    },
];

const columns = [
    {
        field: "Id",
        width: "0%",
    },
    {
        field: "Name",
        headerName: "Vorname und Nachname",
    },
    {
        field: "Role",
        headerName: "Rolle",
        width: "10%",
    },
    {
        field: "Date",
        headerName: "Datum der Anmeldung",
        width: "15%",
        mobileHidden: true
    },
    {
        field: "Points",
        headerName: "Punkte",
        width: "5%",
        mobileHidden: true
    },
    {
        field: "News",
        headerName: "Nachrichten",
        width: "5%",
        mobileHidden: true
    },
    {
        field: "Events",
        headerName: "Veranstaltungen",
        width: "5%",
        mobileHidden: true
    },
    {
        field: "Followers",
        headerName: "Anhänger",
        mobileHidden: true
    },
    {
        field: "Companies",
        headerName: "Unternehmen",
        width: "8%",
        mobileHidden: true
    },
    {
        field: "Button",
        headerName: "",
        flex: 1,
    },
];

export default function Users() {
    const classes = useStyles();
    const history = useHistory();

    const [roles, setRoles] = React.useState("Roles");
    const [dateRegistered, setDateRegistered] = React.useState("descending");
    const [category, setCategory] = React.useState("Category");
    const [users, setUsers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);

    checkLoggedIn(history);

    React.useEffect(() => {
        getUsersPagination(1, PER_PAGE, localStorage.getItem("token")).then(
            (data) => {
                data.list = transformUserData(data.list);
                setUsers(data);
                setIsLoading(false);
            }
        ).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
    }, []);

    const fetchUserData = (page, role, date) => {
        getUsersPagination(
            page,
            PER_PAGE,
            localStorage.getItem("token"),
            role,
            date
        ).then((data) => {
            data.list = transformUserData(data.list);
            setUsers(data);
        });
    }

    const handlePageChange = (e, newPage) => {
        setCurrentPage(newPage);
        fetchUserData(newPage, roles, dateRegistered);
    };

    const handleRoleChange = (_, target) => {
        setRoles(target.props.value);
        fetchUserData(currentPage, target.props.value, dateRegistered);
    };

    const handleDateRegisteredChange = (_, target) => {
        setDateRegistered(target.props.value);
        fetchUserData(currentPage, roles, target.props.value);
    };

    const handleCategoryChange = (_, target) => {
        setCategory(target.props.value);
    };

    const handleButtonClick = (id) => {
        history.push({ pathname: "/userInfo", state: { userId: id } });
    };

    return (
        <Layout currentScreen={data.navigationItems.users}>
            {isLoading ? (
                <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    height="100vh"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box className={classes.root}>
                    <Box className={classes.filters}>
                        <Select
                            className={classes.select}
                            value={roles}
                            onChange={handleRoleChange}
                            disableUnderline
                        >
                            {roleItems.map((el) => {
                                return (
                                    <MenuItem value={el.value}>
                                        {el.id}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Select
                            className={classes.select}
                            value={dateRegistered}
                            onChange={handleDateRegisteredChange}
                            disableUnderline
                        >
                            {dateRegisteredItems.map((el) => {
                                return (
                                    <MenuItem value={el.value}>
                                        {el.id}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Select
                            className={classes.select}
                            value={category}
                            onChange={handleCategoryChange}
                            disableUnderline
                        >
                            {categoryItems.map((el) => {
                                return (
                                    <MenuItem value={el.value}>
                                        {el.id}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                    <CustomTable
                        columns={columns}
                        data={users}
                        buttonText="Profil anzeigen"
                        rowsPerPage={7}
                        onButtonClick={handleButtonClick}
                        handlePageChange={handlePageChange}
                    />
                </Box>
            )}
        </Layout>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    filters: {
        width: "90%",
        height: "10vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    select: {
        border: "2px solid #EDEDED",
        borderRadius: 4,
        flex: 1,
        margin: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            margin: 0
        }
    },
}));
