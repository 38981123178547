import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { ReactComponent as ActiveCompaniesIcon } from "../svg/ActiveCompaniesIcon.svg";
import { ReactComponent as InactiveCompaniesIcon } from "../svg/InactiveCompaniesIcon.svg";
import { ReactComponent as ActiveDashboardIcon } from "../svg/ActiveDashboardIcon.svg";
import { ReactComponent as InactiveDashboardIcon } from "../svg/InactiveDashboardIcon.svg";
import { ReactComponent as InactiveUsersIcon } from "../svg/InactiveUsersIcon.svg";
import { ReactComponent as ActiveUsersIcon } from "../svg/ActiveUsersIcon.svg";
import { ReactComponent as InactivePackagesIcon } from "../svg/InactivePackagesIcon.svg";
import { ReactComponent as ActivePackagesIcon } from "../svg/ActivePackagesIcon.svg";
import { ReactComponent as InactiveStatisticsIcon } from "../svg/InactiveStatisticsIcon.svg";
import { ReactComponent as ActiveStatisticsIcon } from "../svg/ActiveStatisticsIcon.svg";
import { ReactComponent as LogoutIcon } from "../svg/LogoutIcon.svg";

const items = [
    {
        label: "Dashboard",
        activeIcon: <ActiveDashboardIcon />,
        inactiveIcon: <InactiveDashboardIcon />,
        route: "/dashboard",
    },
    {
        label: "Unternehmen",
        activeIcon: <ActiveCompaniesIcon />,
        inactiveIcon: <InactiveCompaniesIcon />,
        route: "/companies",
    },
    {
        label: "Benutzer",
        activeIcon: <ActiveUsersIcon />,
        inactiveIcon: <InactiveUsersIcon />,
        route: "/users",
    },
    {
        label: "Pakete",
        activeIcon: <ActivePackagesIcon />,
        inactiveIcon: <InactivePackagesIcon />,
        route: "/packages",
    },
    {
        label: "Statistik",
        activeIcon: <ActiveStatisticsIcon />,
        inactiveIcon: <InactiveStatisticsIcon />,
        route: "/statistics",
    },
];

export default function MaguDrawer(props) {
    const classes = useStyles();
    const history = useHistory();
    const [activeItem, setActiveItem] = React.useState(
        props.currentScreen || 0
    );

    const handleLogout = () => {
        localStorage.setItem("token", "");
        history.push("/");
    };

    const navigationItems = items.map((el, index) => {
        return (
            <Box
                className={classes.item}
                onClick={() => {
                    setActiveItem(index);
                    history.push(el.route);
                }}
            >
                <Box width={"65%"} display={"flex"} flexDirection={"row"}>
                    {activeItem === index ? el.activeIcon : el.inactiveIcon}
                    <Typography
                        className={`${classes.itemLabel} ${
                            activeItem === index && classes.activeItemLabel
                        }`}
                    >
                        {el.label}
                    </Typography>
                </Box>
                {activeItem === index && (
                    <Box className={classes.rightBorder} />
                )}
            </Box>
        );
    });

    return (
        <Box className={classes.root} {...props.style}>
            <Box>
                <img src="./logo.png" />
            </Box>
            <Typography className={classes.title}>MAGU</Typography>
            <Typography className={classes.subtitle}>superadmin</Typography>
            <Box style={{ marginTop: "25%" }}>{navigationItems}</Box>

            <Box
                className={classes.item}
                style={{ marginTop: "auto" }}
                onClick={handleLogout}
            >
                <Box width={"65%"} display={"flex"} flexDirection={"row"}>
                    <LogoutIcon />
                    <Typography className={classes.itemLabel}>
                        Abmelden
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
    item: {
        width: "100%",
        height: "22%",
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
    },
    itemLabel: {
        color: "#aaaaaa",
        fontSize: "2.2vh",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            fontSize: 14
        }
    },
    activeItemLabel: {
        color: "black",
    },
    title: {
        color: "black",
        fontWeight: 800,
        fontSize: "2.7vh",
        [theme.breakpoints.down("xs")]: {
            fontSize: 20
        }
    },
    subtitle: {
        color: "#aaaaaa",
        fontWeight: 400,
        fontSize: "2.7vh",
        [theme.breakpoints.down("xs")]: {
            fontSize: 20
        }
    },
    rightBorder: {
        background: "#4331D7",
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        position: "absolute",
        top: "10%",
        bottom: "10%",
        left: "96%",
        right: 0,
    },
}));
