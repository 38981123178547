import React from "react";
import {
    Typography,
    Box,
    Drawer,
    Divider,
    Hidden,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaguDrawer from "./MaguDrawer";
import { ReactComponent as BurgerIcon } from "../svg/BurgerIcon.svg";

export default function Layout(props) {
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = React.useState(false);

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Hidden smUp>
                    <Box onClick={() => setShowDrawer(!showDrawer)} style={{ marginLeft: "5%"}}>
                        <BurgerIcon />
                    </Box>
                </Hidden>
                <Hidden xsDown>
                    <Box className={classes.drawerHeader}></Box>
                    <Typography className={classes.itemLeft}>Vorschau</Typography>
                </Hidden>
                <Hidden smUp>
                    <Typography className={classes.itemRight}>Vorschau</Typography>
                </Hidden>
                
            </Box>
            <Divider
                className={classes.divider}
                classes={{ root: classes.dividerRoot }}
            />
            <Hidden xsDown>
                <Drawer
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    anchor="left"
                >
                    <MaguDrawer currentScreen={props.currentScreen} />
                </Drawer>
            </Hidden>
            <Hidden smUp>
                {showDrawer && (
                    <Drawer
                        className={classes.drawer}
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left"
                        variant="permanent"
                        onClose={() => setShowDrawer(false)}
                    >
                        <MaguDrawer currentScreen={props.currentScreen} style={{ width: "100%"}} />
                    </Drawer>
                )}
            </Hidden>

            {props.children}
        </Box>
    );
}

const calculate = (objectPx, totalPx = 1440) => {
    return (objectPx / totalPx) * 100 + "%";
};

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        marginLeft: `max(180px, ${calculate(245)})`, // max calculation similarly picks the largest from a comma separated list of values (of any length).
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
        },
        position: "relative",
    },
    header: {
        backgroundColor: "#FAFAFA",
        width: "100%",
        height: 80,
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        zIndex: 10,
    },
    drawer: {
        background: "red",
        width: calculate(240),
        flexShrink: 0,
    },
    drawerPaper: {
        color: "white",
        width: calculate(240),
        backgroundColor: "#F3F9FC",
        textAlign: "center",
        minWidth: 180,
        marginTop: 55,
        [theme.breakpoints.down("xs")]: {
            width: "40%",
        },
    },
    drawerHeader: {
        height: "100%",
        width: calculate(241),
        display: "flex",
        minWidth: 181,
        position: "relative",

        backgroundColor: "#F3F9FC",
        justifyContent: "flex-start",
        alignItems: "inherit",
        "& p": {
            fontSize: 22,
            color: "white",
        },
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down("xs")]: {
            "& p": {
                fontSize: 16,
            },
        },
    },
    itemLeft: {
        marginRight: "auto",
        marginLeft: theme.spacing(10),
        fontSize: "1.9vh",
        color: "#BCBCBC",
        fontWeight: 600,
    },
    itemRight: {
        marginRight: "5%",
        marginLeft: "auto",
        fontSize: "1.9vh",
        color: "#BCBCBC",
        fontWeight: 600,
    },
    divider: {
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    dividerRoot: {
        background: "#BCBCBC",
    },
}));
