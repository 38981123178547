import React from "react";
import { Container, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function EventsCard(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>VERANSTALTUNGEN</Typography>
            <Box className={classes.row}>
                {props.icon}
                <Box className={classes.item} style={{ paddingLeft: "5%"}}>
                    <Typography className={classes.value}>
                        {props.valueActive}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        style={{ color: "#20B149" }}
                    >
                        AKTIV
                    </Typography>
                </Box>
                <Box className={classes.item} style={{ borderLeft: "1px solid #AAAAAA", }}>
                    <Typography className={classes.value}>
                        {props.valuePassed}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        style={{
                            color: "#B12020",
                        }}
                    >
                        ALT
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "auto"
    },
    item: {
        display: "flex",
        flexDirection: "column",
        width: "30%",
        paddingLeft: "10%"
    },
    value: {
        fontSize: "4.1vh",
        fontWeight: 700,
        color: "#4331D7",
    },
    subtitle: {
        fontSize: "1.7vh",
        fontWeight: 500,
    },
    title: {
        fontSize: "1.7vh",
        fontWeight: 700,
        color: "#2D2D2D",
        marginTop: "2%",
        marginLeft: "2%"
    },
}));
