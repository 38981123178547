import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { ReactComponent as CloseIcon } from "../../svg/CloseIcon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customCall, getSalesmenForCompany } from "../../helper/externalCalls";

export default function ManageCompany(props) {
    const classes = useStyles();
    const history = useHistory();
    const [company, setCompany] = React.useState({});
    const [category, setCategory] = React.useState("");
    const [salesmenList, setSalesmenList] = React.useState([]);

    React.useEffect(() => {
        customCall(`/company/${props.companyId}`, "GET", localStorage.getItem("token")).then((data) => {
            setCompany(data);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
    }, []);

    React.useEffect(() => {
        if(company.CompanyCategoryIds){
            company.CompanyCategoryIds.forEach((id) => {
                customCall(`/companycategory/${id}`, "GET", localStorage.getItem("token")).then((data) => {
                    setCategory(`${category}${category !== "" ? ", " : ""}${data.Category}`);
                });
            });
        }
        if(company.CompanyId){
            getSalesmenForCompany(company.CompanyId, localStorage.getItem("token")).then((data) => {
                const salesmen = data.map((e) => e.Salesman);
                setSalesmenList(salesmen);
            });
        }
    }, [company])

    const salesman = (imageUrl, name) => (
        <Box className={classes.row} style={{ marginBottom: "3%" }}>
            <img src={imageUrl || "./ProfileImage.png"} width="10%" />
            <Typography style={{ marginLeft: "5%" }}>{name}</Typography>
        </Box>
    );

    return (
        <Box className={classes.root}>
            <Box
                className={classes.row}
                style={{ justifyContent: "space-between" }}
            >
                <Typography className={classes.title}>{company.CompanyName}</Typography>
                <CloseIcon style={{ cursor: "pointer" }} onClick={props.handleCloseClick} />
            </Box>
            <Box className={classes.row} style={{ marginBottom: "7%" }}>
                <img src={company.ProfileImage?.Url || "./ProfileImage.png"} className={classes.imageSize} />
                <Box className={classes.categoryContainer}>
                    <Typography className={classes.category}>
                        Kategorie:
                    </Typography>
                    <Typography className={classes.categoryValue}>
                        {category}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.salesmenContainer}>
                <Typography style={{ fontSize: "2.3vh", marginBottom: "3%" }}>
                    Berater:
                </Typography>
                <Box height={"80%"}>
                    <Scrollbars
                        color="#fff"
                        style={{ width: "100%", height: "100%" }}
                        renderThumbVertical={({ style, ...props }) => (
                            <div
                                {...props}
                                style={{
                                    ...style,
                                    backgroundColor: "#4068DF",
                                    width: "5px",
                                    borderRadius: "10px",
                                }}
                            />
                        )}
                    >
                        {salesmenList.map((entry) => {
                            return salesman(
                                entry.ProfilePictureUrl,
                                entry.FirstName + " " + entry.LastName
                            );
                        })}
                    </Scrollbars>
                </Box>
            </Box>
            <Button className={classes.button} onClick={() => history.push({ pathname: "/editCompany", state: { company: company }})}>Bearbeiten</Button>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "40vw",
        height: "70vh",
        background: "white",
        display: "flex",
        flexDirection: "column",
        borderRadius: 16,
        padding: "2%",
        paddingLeft: "3%",
        [theme.breakpoints.down("xs")]: {
            width: "90%"
        }
    },
    title: {
        fontWeight: 700,
        fontSize: "2.5vh",
        marginBottom: "2%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    categoryContainer: {
        marginLeft: "5%",
        display: "flex",
        flexDirection: "column",
    },
    category: {
        fontSize: "2.2vh",
        color: "#4068DF",
    },
    categoryValue: {
        fontSize: "2.2vh",
        color: "#2D2D2D",
    },
    button: {
        width: "100%",
        height: "10%",
        marginLeft: "auto",
        marginRight: "auto",
        background: "#4068DF",
        color: "white",
        borderRadius: 10,
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        marginTop: "2%",
        textTransform: "none",
        fontWeight: 700,
        fontSize: "2.1vh",
        "&:hover": {
            background: "#4068DF",
        },
    },
    salesmenContainer: {
        height: "60%",
        overflow: "auto",
    },
    imageSize: {
        width: "40%",
    }
}));
