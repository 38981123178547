import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";
import AddCompany from "./AddCompany";
import { ReactComponent as DeactivateUserIcon } from "../../svg/DeactivateUserIcon.svg";
import { useLocation, useHistory } from "react-router-dom";
import { checkLoggedIn } from "../../helper/helper";
import { deleteCompany, updateCompany } from "../../helper/externalCalls";

const companyImages = {
    cover: "./CoverImage.jpg",
    profile: "./ProfileImage.png",
};

export default function EditCompany(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    checkLoggedIn(history);

    const companyData = React.useRef(location.state.company);

    const handlePropertyChange = (propertyName, value) => {
        companyData.current[propertyName] = value;
    };

    const handleSaveChanges = () => {
        const company = {
            companyName: companyData.current.CompanyName,
            companyLink: companyData.current.CompanyLink,
            about: companyData.current.About,
            location: companyData.current.Location || { LocationId: null },
            companyCategoryId: companyData.current.CompanyCategoryIds,
            fileData: {
                profile: companyData.current.profile || companyData.current.ProfileImage,
                cover: companyData.current.cover || companyData.current.HeaderImage,
                video: companyData.current.video || companyData.current.Video
            },
            companyNetworks: companyData.current.CompanyLinks
        };
        updateCompany(companyData.current.CompanyId, company, localStorage.getItem("token")).then(() => {
            history.push("/companies");
        }).catch((err) => console.log(err));
    };

    const handleDeleteCompany = () => {
        deleteCompany(companyData.current.CompanyId, localStorage.getItem("token")).then(() => {
            history.push("/companies");
        });
    }

    return (
        <Layout currentScreen={data.navigationItems.companies}>
            <AddCompany
                isEdit
                company={companyData.current}
                handlePropertyChange={handlePropertyChange}
            />
            <Box className={classes.stickyFooter}>
                <Button className={classes.footerButton} onClick={handleSaveChanges}>
                    Änderungen speichern
                </Button>
                <Button
                    className={classes.footerButton}
                    onClick={handleDeleteCompany}
                    style={{
                        background: "white",
                        color: "#FF3939",
                        border: "1px solid #FF3939",
                    }}
                >
                    <DeactivateUserIcon
                        style={{ marginRight: "2%", marginLeft: "-2%" }}
                    />
                    Unternehmen löschen
                </Button>
            </Box>
        </Layout>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    stickyFooter: {
        position: "absolute",
        bottom: "0%",
        top: "90%",
        left: 0,
        right: 0,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    footerButton: {
        width: "22%",
        height: "70%",
        marginLeft: "1%",
        marginRight: "1%",
        textTransform: "none",
        color: "white",
        background: "#4068DF",
        borderRadius: 10,
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        fontSize: "2.1vh",
        fontWeight: 700,
        "&:hover": {
            background: "#4068DF",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
            width: "30%"
        }
    },
}));
