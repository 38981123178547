import React from "react";
import { Button, Box, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createSocialMedia } from "../../helper/externalCalls";

export default function AddSocialMedia(props) {
    const classes = useStyles();
    const [newSocialMedia, setNewSocialMedia] = React.useState("");

    const onInputChange = (e) => {
        setNewSocialMedia(e.target.value);
    };

    const onButtonClick = () => {
        createSocialMedia(newSocialMedia, localStorage.getItem("token")).then((data) => {
            props.closeModal();
        });
    };

    return (
        <Box className={classes.root}>
            <Input
                className={classes.input}
                type="text"
                placeholder="Neu Social Media"
                disableUnderline
                onChange={onInputChange}
            />
            <Button className={classes.button} onClick={onButtonClick}>
                Add media
            </Button>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
    },
    input: {
        width: "90%",
        height: "30%",
        marginBottom: "5%",
        background: "#F4F4F4",
        borderRadius: 10,
        paddingLeft: "1%",
    },
    button: {
        background: "#4068DF",
        color: "white",
        borderRadius: 5,
        width: "50%",
        textTransform: "none",
        "&:hover": {
            background: "#4068DF",
        },
    },
}));
