
function transformUserData(users) {
    return users.map((user) => {
        const date = new Date(user.UserCreatedTime);
        const day = date.getDate().toString().padStart(2, '0'), month = (date.getMonth() + 1).toString().padStart(2, '0'), year = date.getFullYear();
        return {
            Id: user.Id,
            Name: user.FirstName + " " + user.LastName,
            Role: user.UserType,
            Date: `${day}-${month}-${year}`,
            Points: user.Points,
            News: user.NumberOfNews,
            Events: user.NumberOfEvents,
            Followers: user.NumberOfFollowers,
            Companies: user.NumberOfCompanies,
            Picture: user.ProfilePictureUrl
        }
    });
}

function transformCompanyData(companies) {
    return companies.map((company) => {
        return {
            Id: company.CompanyId,
            Name: company.CompanyName,
            Category: company.Categories,
            Salesmen: company.NumberOfSalesmen,
            Picture: company.ProfilePictureUrl
        };
    });
}

function transformUserPackegeData(users){
    return users.map((user) => {
        return {
            Name: user.FirstName + " " + user.LastName,
            Package: "/",
            Price: "/",
            Times: "/",
            Revenue: "/",
            Picture: user.ProfilePictureUrl
        };
    });
}

export { transformUserData, transformCompanyData, transformUserPackegeData };