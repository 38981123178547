import React from "react";
import {
    Typography,
    Grid,
    Paper,
    Box,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "../Layout";
import BasicCard from "./BasicCard";
import BigCard from "./BigCard";
import TotalCard from "./TotalCard";
import EventsCard from "./EventsCard";

import { ReactComponent as AllUsersIcon } from "../../svg/AllUsersIcon.svg";
import { ReactComponent as GoldMedalIcon } from "../../svg/GoldMedalIcon.svg";
import { ReactComponent as SilverMedalIcon } from "../../svg/SilverMedalIcon.svg";
import { ReactComponent as BronzeMedalIcon } from "../../svg/BronzeMedalIcon.svg";
import { ReactComponent as MonthUsersIcon } from "../../svg/MonthUsersIcon.svg";
import { ReactComponent as SalesmenIcon } from "../../svg/SalesmenIcon.svg";
import { ReactComponent as EventsIcon } from "../../svg/EventsIcon.svg";

import data from "../../helper/data.json";
import { getDashboardData } from "../../helper/externalCalls";
import { checkLoggedIn } from "../../helper/helper";
import { useHistory } from "react-router-dom";

const basicItems = [
    {
        id: "AllUsers",
        title: "ALLE BENUTZER",
        icon: <AllUsersIcon />,
        value: 0,
        color: "#4331D7",
    },
    {
        id: "GoldPackages",
        title: "GOLD-PAKETE",
        icon: <GoldMedalIcon />,
        value: "/",
        color: "#FFCC5B",
    },
    {
        id: "SilverPackages",
        title: "SILBER-PAKETE",
        icon: <SilverMedalIcon />,
        value: "/",
        color: "#ADADAD",
    },
    {
        id: "BronzePackages",
        title: "BRONZE-PAKETE",
        icon: <BronzeMedalIcon />,
        value: "/",
        color: "#CD7F32",
    },
];

const monthItems = [
    {
        id: "GainedUsersForCurrentMonth",
        percentageId: "GainedUsersPercentage",
        title: "BENUTZER",
        value: 0,
        icon: <MonthUsersIcon />,
        lastMonth: 0,
    },
    {
        id: "GainedSalesmenForCurrentMonth",
        percentageId: "GainedSalesmenPercentage",
        title: "SALESMEN",
        value: 0,
        icon: <SalesmenIcon />,
        lastMonth: 0,
    },
];

const totalItems = [
    {
        id: "TotalNews",
        title: "NEWS",
        value: 0,
    },
    {
        id: "TotalCompanies",
        title: "UNTERNEHMEN",
        value: 0,
    },
    {
        id: "TotalSalesmen",
        title: "SALESMEN",
        value: 0,
    },
];

export default function Dashboard(props) {
    const classes = useStyles();

    const [dashboardData, setDashboardData] = React.useState({});
    const [basicItemsData, setBasicItemsData] = React.useState(basicItems);
    const [monthItemsData, setMonthItemsData] = React.useState(monthItems);
    const [totalItemsData, setTotalItemsData] = React.useState(totalItems);
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();

    checkLoggedIn(history);

    React.useEffect(() => {
        getDashboardData(localStorage.getItem("token")).then((res) => {
            setDashboardData(res);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
    }, []);

    React.useEffect(() => {
        if(!dashboardData.TotalSalesmen){
            return;
        }
        setBasicItemsData(updateData(basicItemsData, dashboardData));
        setMonthItemsData(updateData(monthItemsData, dashboardData));
        setTotalItemsData(updateData(totalItemsData, dashboardData));
        setIsLoading(false);
    }, [dashboardData]);

    const basicData = basicItemsData.map((el) => {
        return (
            <Paper className={`${classes.paper} ${classes.smallerHeight}`}>
                <BasicCard
                    title={el.title}
                    icon={el.icon}
                    value={el.value}
                    color={el.color}
                />
            </Paper>
        );
    });

    const basic = [];
    for(var i = 0; i < 4; i+=2){
        basic.push(
            <Box className={classes.rowResponsive} flex={1}>
                {basicData[i]}
                {basicData[i+1]}
            </Box>
        );
    }

    const thisMonth = monthItemsData.map((el) => {
        return (
            <Paper className={classes.paper}>
                <BigCard
                    title={el.title}
                    value={el.value}
                    lastMonth={el.lastMonth}
                    icon={el.icon}
                />
            </Paper>
        );
    });

    const total = totalItemsData.map((el) => {
        return (
            <Paper className={classes.paper}>
                <TotalCard title={el.title} value={el.value} />
            </Paper>
        );
    });

    return (
        <Layout currentScreen={data.navigationItems.dashboard}>
            {isLoading ? (
                <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    height={"80vh"}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box className={classes.root}>
                    <Box
                        className={`${classes.row} ${classes.label}`}
                    >
                        <Typography className={classes.title}>
                            Grundlegend
                        </Typography>
                    </Box>
                    <Box className={classes.row}>
                        {basic}
                    </Box>
                    <Box
                        className={`${classes.row} ${classes.label}`}
                    >
                        <Typography className={classes.title}>
                            Diesen Monat
                        </Typography>
                    </Box>
                    <Box className={classes.rowResponsive}>
                        {thisMonth}
                    </Box>
                    <Box
                        className={`${classes.row} ${classes.label}`}
                    >
                        <Typography className={classes.title}>
                            Insgesamt
                        </Typography>
                    </Box>
                    <Box className={classes.rowResponsive}>
                        <Paper className={`${classes.bigPaper} ${classes.paper}`}>
                            <EventsCard
                                icon={<EventsIcon />}
                                valueActive={dashboardData?.ActiveEvents}
                                valuePassed={dashboardData?.ExpiredEvents}
                            />
                        </Paper>
                        {total}
                    </Box>
                </Box>
            )}
        </Layout>
    );
}

const updateData = (items, data) => {
    return items.map((el) => {
        if (data[el.id]) {
            el.value = data[el.id];
        }
        if (data[el.percentageId]) {
            el.lastMonth = data[el.percentageId];
        }
        return el;
    });
};

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    paper: {
        flex: 1,
        height: "17vh",
        marginLeft: "1%",
        marginRight: "1%",
        marginTop: "1%",
        boxShadow: "0px 4px 30px rgba(182, 182, 182, 0.25)",
        borderRadius: 10,
        "&:hover": {
            background: "#F3F9FC",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "5%",
            minWidth: "40%"
        }
    },
    smallerHeight: {
        height: "15vh"
    },
    bigPaper: {
        flex: 2,
        [theme.breakpoints.down("xs")]:{
            width: "80%"
        }
    },
    row: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    rowResponsive: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        }
    },
    label: {
        marginTop: "5vh",
    },
    title: {
        fontSize: "2.1vh",
        color: "#AAAAAA",
        fontWeight: 600,
    },
}));
