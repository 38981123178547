import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function BigCard(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.itemLeft}>
                <Typography className={classes.title}>{props.title}</Typography>
                <Typography className={classes.value}>{props.value}</Typography>
                <Typography
                    className={classes.lastMonth}
                    style={
                        props.lastMonth >= 0
                            ? { color: "#20B149" }
                            : { color: "#B12020" }
                    }
                >
                    {props.lastMonth >= 0 && "+"}
                    {props.lastMonth}
                    {"% gegenüber dem Vormonat"}
                </Typography>
            </Box>
            <Box className={classes.itemRight}>{props.icon}</Box>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    itemLeft: {
        display: "flex",
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "5%"
    },
    itemRight: {
        marginLeft: "auto",
        marginRight: "5%"
    },
    title: {
        fontSize: "1.7vh",
        fontWeight: 700,
        color: "#2D2D2D",
    },
    value: {
        fontSize: "5.1vh",
        fontWeight: 700,
        color: "#4331D7",
    },
    lastMonth: {
        fontSize: "1.7vh",
        fontWeight: 500,
    },
}));
