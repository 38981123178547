import React from "react";
import {
    Button,
    Box,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";

const hiddenMobileProperties = [
    "Date",
    "Points",
    "News",
    "Events",
    "Followers",
    "Companies",
    "Salesmen"
];

export default function CustomTable(props) {
    const classes = useStyles();
    const [data, setData] = React.useState(props.data || []);
    const [selectedRow, setSelectedRow] = React.useState(-1);

    React.useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const nameAndSurname = (name, imageUrl) => {
        return (
            <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                height="6vh"
            >
                <Hidden xsDown>
                    <img
                        src={imageUrl || "./ProfileImage.png"}
                        style={{
                            borderRadius: "50%",
                            width: props.imageWidth || "25%",
                            height: props.imageHeight || "100%",
                        }}
                    />
                </Hidden>
                <Typography style={{ marginLeft: "5%" }}>{name}</Typography>
            </Box>
        );
    };

    console.log(data)

    return (
        <Box className={classes.root}>
            <TableContainer
                style={{
                    marginLeft: "1%",
                    marginRight: "1%",
                    boxShadow: "0px 4px 10px 5px rgba(176, 188, 231, 0.06)",
                    borderRadius: 5,
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((el) => {
                                if (el.field === "Id") return;

                                const column = (
                                    <TableCell>
                                        <Typography
                                            className={classes.headerText}
                                        >
                                            {el.headerName}
                                        </Typography>
                                    </TableCell>
                                );

                                if (el.mobileHidden) {
                                    return <Hidden xsDown>{column}</Hidden>;
                                }

                                return column;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.list?.map((el, index) => {
                            return (
                                <TableRow
                                    className={`${classes.row} ${
                                        selectedRow === index &&
                                        classes.selectedRow
                                    }`}
                                    onClick={() => {
                                        setSelectedRow(index);
                                    }}
                                >
                                    {Object.keys(el).map((key, objectIndex) => {
                                        if (key === "Id") {
                                            return;
                                        }
                                        let component = {};
                                        if (key === "Name") {
                                            component = (
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    {nameAndSurname(
                                                        el[key],
                                                        el["Picture"]
                                                    )}
                                                </TableCell>
                                            );
                                        } else if (key === "Picture") {
                                            component = (
                                                <TableCell
                                                    className={classes.cell}
                                                >
                                                    <Button
                                                        className={
                                                            classes.button
                                                        }
                                                        onClick={() => {
                                                            props.onButtonClick(
                                                                el["Id"]
                                                            );
                                                        }}
                                                        style={
                                                            selectedRow ===
                                                            index
                                                                ? {
                                                                      background:
                                                                          "#3D9DE7",
                                                                      color: "white",
                                                                  }
                                                                : {
                                                                      background:
                                                                          "transparent",
                                                                      color: "black",
                                                                  }
                                                        }
                                                    >
                                                        {props.buttonText ||
                                                            "Button text"}
                                                    </Button>
                                                </TableCell>
                                            );
                                        } else {
                                            component = (
                                                <TableCell
                                                    className={classes.cell}
                                                    width={
                                                        props.columns[
                                                            objectIndex
                                                        ]?.width
                                                    }
                                                >
                                                    {el[key] === undefined
                                                        ? "-"
                                                        :(
                                                        Array.isArray(el[key]) ?
                                                        el[key].join(", ")
                                                        :
                                                         el[key])}
                                                </TableCell>
                                            );
                                        }

                                        if (
                                            hiddenMobileProperties.includes(key)
                                        ) {
                                            return (
                                                <Hidden xsDown>
                                                    {component}
                                                </Hidden>
                                            );
                                        }

                                        return component;
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                page={data.CurrentPage}
                count={data.TotalPages}
                shape="rounded"
                variant="outlined"
                color="primary"
                onChange={props.handlePageChange}
                className={classes.pagination}
                size="large"
            />
            {props.alert}
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
    },
    pagination: {
        marginLeft: "auto",
        marginRight: "auto",
        "& .Mui-selected": {
            backgroundColor: "#4068DF",
            color: "white",
            "&:hover": {
                backgroundColor: "#4068DF",
                color: "white",
            },
            margin: theme.spacing(1),
        },
        "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
            {
                backgroundColor: "transparent",
                color: "#AAAAAA",
                margin: theme.spacing(1),
            },
        "& .MuiPaginationItem-icon": {
            backgroundColor: "transparent",
            color: "#4068DF",
        },
    },
    row: {
        "&:hover": {
            background: "#F3F9FC",
        },
        cursor: "pointer",
    },
    selectedRow: {
        background: "rgb(61, 157, 231, .2)",
        "&:hover": {
            background: "rgb(61, 157, 231, .25)",
        },
    },
    button: {
        borderRadius: 10,
        textTransform: "none",
        width: "80%",
    },
    cell: {
        border: "none",
    },
    headerText: {
        fontSize: "1.5vh",
        color: "#3D9DE7",
    },
}));
