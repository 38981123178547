import { BrowserRouter, Route } from "react-router-dom";

import './App.css';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Companies from "./components/companies/Companies";
import Users from "./components/users/Users";
import Packages from "./components/packages/Packages";
import Statistics from "./components/statistics/Statistics";
import UserInfo from "./components/userInfo/UserInfo";
import EditCompany from "./components/companies/EditCompany";

const theme = createTheme({
  typography: {
      fontFamily: ["Poppins", "Sans-serif"].join(","),
  },
  palette: {
      primary: { 500: "#21344D" },
      secondary: { main: "#bababa" },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route exact path={"/"} component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/companies" component={Companies}/>
        <Route path="/users" component={Users}/>
        <Route path="/packages" component={Packages}/>
        <Route path="/statistics" component={Statistics}/>
        <Route path="/userInfo" component={UserInfo}/>
        <Route path="/editCompany" component={EditCompany}/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
