import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";
import CustomGraph from "./CustomGraph";
import Scrollbars from "react-custom-scrollbars-2";
import { checkLoggedIn } from "../../helper/helper";
import { useHistory } from "react-router-dom";

export default function Statistics() {
    checkLoggedIn(useHistory());

    const classes = useStyles();
    const [overviewData, setOverviewData] = React.useState([]);
    const [goldData, setGoldData] = React.useState([]);
    const [silverData, setSilverData] = React.useState([]);
    const [bronzeData, setBronzeData] = React.useState([]);

    React.useEffect(() => {
        setOverviewData(getData(31));
        setGoldData(getData(31));
        setSilverData(getData(31));
        setBronzeData(getData(31));
    }, []);

    return (
        <Layout currentScreen={data.navigationItems.statistics}>
            <Scrollbars
                color="#fff"
                style={{ width: "100%", height: "90vh" }}
                renderThumbVertical={({ style, ...props }) => (
                    <div
                        {...props}
                        style={{
                            ...style,
                            backgroundColor: "#4068DF",
                            width: "5px",
                            borderRadius: "10px",
                        }}
                    />
                )}
            >
                <CustomGraph
                    name="Benutzer Übersicht"
                    percentage={4}
                    lastMonth="Mai"
                    data={overviewData}
                    color="#4331D7"
                />
                <CustomGraph
                    style={{ marginTop: "5%" }}
                    name="Gold-Paket"
                    percentage={4}
                    lastMonth="Mai"
                    data={goldData}
                    color="#FFCC5B"
                />
                <CustomGraph
                    style={{ marginTop: "5%" }}
                    name="Silber-Paket"
                    percentage={4}
                    lastMonth="Mai"
                    data={silverData}
                    color="#AAAAAA"
                />
                <CustomGraph
                    style={{ marginTop: "5%" }}
                    name="Bronze-Paket"
                    percentage={4}
                    lastMonth="Mai"
                    data={bronzeData}
                    color="#CD7F32"
                />
            </Scrollbars>
        </Layout>
    );
}

function getData(days) {
    const ret = [];
    for (let i = 0; i < days; i++) {
        ret.push({
            day: i + 1,
            value: Math.floor(Math.random() * 1000),
        });
    }
    return ret;
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));
