import React from "react";
import {
    Typography,
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    Input,
    InputLabel,
    createTheme,
    MuiThemeProvider,
    Modal,
    Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as CloseIcon } from "../../svg/CloseIcon.svg";
import { ReactComponent as UploadIcon } from "../../svg/UploadIcon.svg";
import { ReactComponent as TrashIcon } from "../../svg/TrashIcon.svg";
import { ReactComponent as PlusIcon } from "../../svg/PlusIcon.svg";
import Scrollbars from "react-custom-scrollbars-2";
import {
    getAllCategories,
    getAllSocialMedia,
    getSocialMediaLinks
} from "../../helper/externalCalls";
import AddSocialMedia from "./AddSocialMedia";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import data from "../../helper/data.json";
import { useHistory } from "react-router-dom";

const formLabelsTheme = createTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#FF3939",
            },
        },
    },
});

const allowedImageFormats =
    "image/gif, image/jpeg, image/bmp, image/x-portable-bitmap, image/png";
const allowedVideoFormats =
    "video/x-flv, video/mp4, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv, application/x-mpegURL";

export default function AddCompany(props) {
    const classes = useStyles();
    const history = useHistory();
    const [company, setCompany] = React.useState(props.company || {});
    const [coverImage, setCoverImage] = React.useState(null);
    const [profilePicture, setProfilePicture] = React.useState(null);
    const [companyVideo, setVideo] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [socialNetworks, setSocialNetworks] = React.useState([]);
    const [selectedNetworkId, setSelectedNetworkId] = React.useState(1);
    const [location, setLocation] = React.useState("");

    React.useEffect(() => {
        getAllCategories(localStorage.getItem("token")).then((data) => {
            setCategories(data);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
        setupSocialMedia();
        getSocialMediaLinksForCompany();
    }, []);

    const setupSocialMedia = () => {
        getAllSocialMedia(localStorage.getItem("token")).then((data) => {
            setSocialNetworks(data);
        });
    };

    const getSocialMediaLinksForCompany = () => {
        if(!company.CompanyId)
            return;
        
        getSocialMediaLinks(company.CompanyId, localStorage.getItem("token"))
        .then((data) => {
            const linkObject = {};
            data.SocialNetworks.forEach((socialNetwork, index) => {
                if(index === 0){
                    setSelectedNetworkId(socialNetwork.SocialNetworkId);
                }
                linkObject[socialNetwork.SocialNetworkId] = socialNetwork.Url;
            });
            props.handlePropertyChange(
                "CompanyLinks",
                linkObject
            );
            setCompany((oldVal) => ({
                ...oldVal,
                CompanyLinks: linkObject,
            }));
        }).catch((err) => console.log(err));
    };

    const onLocationChange = (e) => {
        setLocation(e);
        props.handlePropertyChange("Location", e);
    };

    const onUploadButtonClick = (id) => {
        document.getElementById(id).click();
    };

    const onCoverImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            props.company.cover = img;
            setCoverImage(URL.createObjectURL(img));
        }
    };

    const onProfilePictureChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            props.company.profile = img;
            setProfilePicture(URL.createObjectURL(img));
        }
    };

    const onVideoChange = (event) => {
        console.log(event.target.files);
        if (event.target.files && event.target.files[0]) {
            const video = event.target.files[0];
            props.company.video = video;
            setVideo(URL.createObjectURL(video));
        }
    };

    const onDeleteClick = (itemType) => {
        switch (itemType) {
            case "profile":
                setCompany({ ...company, ProfileImage: null });
                setProfilePicture(null);
                break;
            case "cover":
                setCompany({ ...company, HeaderImage: null });
                setCoverImage(null);
                break;
            case "video":
                setVideo(null);
                break;
        }
    };

    return (
        <MuiThemeProvider theme={formLabelsTheme}>
            <Box className={classes.root}>
                {!props.isEdit && (
                    <Box
                        className={classes.row}
                        style={{
                            justifyContent: "space-between",
                            marginBottom: "3%",
                        }}
                    >
                        <Typography className={classes.title}>
                            Neues Unternehmen hinzufügen
                        </Typography>
                        <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={props.handleCloseClick}
                        />
                    </Box>
                )}
                <Scrollbars
                    color="#fff"
                    style={{
                        width: "100%",
                        height: props.isEdit ? "85vh" : "70vh",
                    }}
                    renderThumbVertical={({ style, ...props }) => (
                        <div
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: "#4068DF",
                                width: "5px",
                                borderRadius: "10px",
                                marginLeft: "auto",
                            }}
                        />
                    )}
                >
                    <Box
                        className={classes.coverContainer}
                        style={{
                            background: `url('${
                                coverImage ||
                                company?.HeaderImage?.Url ||
                                "./CoverImage.jpg"
                            }')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <Button
                            className={classes.coverButton}
                            style={{ background: "#4068DF" }}
                            onClick={() => onUploadButtonClick("cover-image")}
                        >
                            <input
                                id="cover-image"
                                type={"file"}
                                accept={allowedImageFormats}
                                hidden
                                onChange={onCoverImageChange}
                            />
                            <UploadIcon />
                        </Button>
                        <Button
                            className={classes.coverButton}
                            style={{ background: "#FADDDD" }}
                            onClick={() => onDeleteClick("cover")}
                        >
                            <TrashIcon />
                        </Button>
                    </Box>
                    <Box className={classes.row} style={{ marginTop: "2%" }}>
                        <Box className={classes.outerCompanyPictureContainer}>
                            <Box
                                className={classes.companyPictureContainer}
                                style={{
                                    background: `url('${
                                        profilePicture ||
                                        company?.ProfileImage?.Url ||
                                        "./ProfileImage.png"
                                    }')`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                            />
                            <Button
                                className={classes.companyPictureButton}
                                style={{ left: 0, background: "#4068DF" }}
                                onClick={() =>
                                    onUploadButtonClick("profile-image")
                                }
                            >
                                <input
                                    id="profile-image"
                                    type={"file"}
                                    accept={allowedImageFormats}
                                    hidden
                                    onChange={onProfilePictureChange}
                                />
                                <UploadIcon />
                            </Button>
                            <Button
                                className={classes.companyPictureButton}
                                style={{ right: 0, background: "#FADDDD" }}
                                onClick={() => onDeleteClick("profile")}
                            >
                                <TrashIcon />
                            </Button>
                        </Box>
                        <Box className={classes.column}>
                            <InputLabel className={classes.inputLabel} required>
                                Name:
                            </InputLabel>
                            <TextField
                                className={classes.inputItem}
                                defaultValue={company.CompanyName}
                                onChange={(e) => {
                                    props.handlePropertyChange(
                                        "CompanyName",
                                        e.target.value
                                    );
                                }}
                            />
                            <InputLabel className={classes.inputLabel}>
                                Kategorie:
                            </InputLabel>
                            <Select
                                multiple
                                defaultValue={company.CompanyCategoryIds || []}
                                className={selectedCategory}
                                onChange={(e) => {
                                    props.handlePropertyChange(
                                        "CompanyCategoryIds",
                                        e.target.value
                                    );
                                }}
                                MenuProps={{ variant: 'menu' }}
                            >
                                {categories.map((c) => (
                                    <MenuItem value={c.CompanyCategoryId}>
                                        {c.Category}
                                    </MenuItem>
                                ))}
                            </Select>
                            <InputLabel className={classes.inputLabel}>
                                E-Mail:
                            </InputLabel>
                            <TextField
                                className={classes.inputItem}
                                defaultValue={company.CompanyLink}
                                onChange={(e) => {
                                    props.handlePropertyChange(
                                        "CompanyLink",
                                        e.target.value
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        className={classes.row}
                        style={{
                            marginTop: "7%",
                            marginBottom: "2%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography className={classes.linkText}>
                            Links hinzufügen
                        </Typography>
                        <Button
                            className={classes.linkButton}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Hidden xsDown>Neu hinzufügen</Hidden>
                            <Hidden smUp>
                                <PlusIcon />
                            </Hidden>
                        </Button>
                    </Box>
                    <Box
                        className={classes.row}
                        style={{
                            height: "5vh",
                            justifyContent: "space-between",
                        }}
                    >
                        <Input
                            type="text"
                            placeholder="Link eingeben"
                            disableUnderline
                            className={classes.linkInput}
                            value={
                                (company.CompanyLinks &&
                                    company.CompanyLinks[selectedNetworkId]) ||
                                ""
                            }
                            onChange={(e) => {
                                const linkObject = { ...company.CompanyLinks };
                                linkObject[selectedNetworkId] = e.target.value;
                                props.handlePropertyChange(
                                    "CompanyLinks",
                                    linkObject
                                );
                                setCompany((oldVal) => ({
                                    ...oldVal,
                                    CompanyLinks: linkObject,
                                }));
                            }}
                        />
                        <Select
                            type=""
                            value={selectedNetworkId}
                            className={classes.selectLink}
                            onChange={(e) =>
                                setSelectedNetworkId(e.target.value)
                            }
                        >
                            {socialNetworks.map((entry) => (
                                <MenuItem value={entry.SocialNetworkId}>
                                    {entry.NetworkName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box style={{ marginTop: "5%" }}>
                        <InputLabel className={classes.inputLabel} required>
                            Beschreibung:
                        </InputLabel>
                        <TextField
                            required
                            style={{ width: "95%" }}
                            defaultValue={company.About}
                            onChange={(e) => {
                                props.handlePropertyChange(
                                    "About",
                                    e.target.value
                                );
                            }}
                        />
                    </Box>
                    <Box style={{ marginTop: "2%" }}>
                        <InputLabel className={classes.inputLabel}>
                            Standort:
                        </InputLabel>
                        <GooglePlacesAutocomplete
                            apiKey={data.mapApiKey}
                            selectProps={{
                                placeholder: company.Location ? `${company.Location?.City}, ${company.Location?.Country}` : "Location",
                                value: location,
                                onChange: onLocationChange,
                            }}
                        />
                    </Box>
                    <Box className={classes.row} style={{ marginTop: "2%" }}>
                        <Typography className={classes.videoText}>
                            Video
                        </Typography>
                    </Box>
                    <Box
                        className={classes.map}
                        style={{
                            justifyContent: "center",
                            marginTop: "2%",
                            marginBottom: "2%",
                            backgroundColor: "transparent",
                            position: "relative",
                            background: `url(./VideoThumbnail.png)`,
                        }}
                    >
                        <video
                            controls
                            src={companyVideo || company.Video?.Url}
                            width={"100%"}
                            height="100%"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                objectFit: "fill",
                            }}
                        />
                        <Button
                            className={classes.videoButton}
                            style={{ background: "#4068DF" }}
                            onClick={() => onUploadButtonClick("company-video")}
                        >
                            <UploadIcon />
                        </Button>
                        <Button
                            className={classes.videoButton}
                            style={{ background: "#FADDDD" }}
                            onClick={() => onDeleteClick("video")}
                        >
                            <TrashIcon />
                        </Button>
                        <input
                            id="company-video"
                            type={"file"}
                            accept={allowedVideoFormats}
                            hidden
                            onChange={onVideoChange}
                        />
                    </Box>
                </Scrollbars>
            </Box>
            <Modal
                className={classes.modal}
                open={isModalOpen}
                onClose={() => {
                    setupSocialMedia();
                    setIsModalOpen(false);
                }}
            >
                <AddSocialMedia
                    closeModal={() => {
                        setupSocialMedia();
                        setIsModalOpen(false);
                    }}
                />
            </Modal>
        </MuiThemeProvider>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "2%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "5%",
        },
    },
    title: {
        fontWeight: 700,
        fontSize: "2.5vh",
    },
    coverContainer: {
        width: "100%",
        height: "25vh",
        borderRadius: 10,
        filter: "drop-shadow(0px 4px 20px rgba(190, 190, 190, 0.06))",
        background: "red",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        marginRight: "auto",
        marginLeft: "auto",
    },
    coverButton: {
        marginLeft: "2%",
        marginRight: "2%",
        marginTop: "2%",
        width: "6%",
        height: "25%",
        borderRadius: 10,
    },
    outerCompanyPictureContainer: {
        position: "relative",
        width: "25vh",
        height: "25vh",
        [theme.breakpoints.down("xs")]: {
            width: "37vw",
            height: "37vw",
        },
    },
    companyPictureContainer: {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
    },
    companyPictureButton: {
        position: "absolute",
        bottom: 0,
        top: "80%",
        width: "45%",
        borderRadius: 10,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        width: "40%",
        marginLeft: "7%",
        [theme.breakpoints.down("xs")]: {
            width: "50%",
        },
    },
    inputItem: {
        marginBottom: "2%",
    },
    linkText: {
        fontWeight: 600,
        fontSize: "2.3vh",
        color: "#929292",
    },
    linkButton: {
        background: "#4068DF",
        color: "white",
        borderRadius: 5,
        width: "20%",
        height: "5vh",
        marginRight: "5%",
        textTransform: "none",
        fontSize: "2.1vh",
        "&:hover": {
            background: "#4068DF",
        },
        [theme.breakpoints.down("xs")]: {
            width: "10%",
        },
    },
    linkInput: {
        background: "#F4F4F4",
        borderRadius: 10,
        width: "70%",
        height: "100%",
        paddingLeft: "1%",
        [theme.breakpoints.down("xs")]: {
            width: "50%",
        },
    },
    selectLink: {
        width: "20%",
        marginRight: "5%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
            width: "40%",
        },
    },
    map: {
        height: "30vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "5%",
        [theme.breakpoints.down("xs")]: {
            height: "20vh",
        },
    },
    videoText: {
        fontWeight: 600,
        fontSize: "2.3vh",
        color: "#929292",
    },
    inputLabel: {
        marginBottom: "2%",
        color: "#4068DF",
        fontSize: "2.3vh",
    },
    videoButton: {
        marginLeft: "1%",
        marginRight: "1%",
        width: "15%",
        height: "30%",
        borderRadius: 10,
    },
    hide: {
        display: "none",
    },
    modal: {
        display: "flex",
        height: "17vh",
        width: "25vw",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    },
}));
