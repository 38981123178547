import React from "react";
import {
    Grid,
    Paper,
    Typography,
    Box,
    Modal,
    Select,
    Button,
    MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";

import { ReactComponent as AllUsersIcon } from "../../svg/AllUsersIcon.svg";
import { ReactComponent as GoldMedalIcon } from "../../svg/GoldMedalIcon.svg";
import { ReactComponent as SilverMedalIcon } from "../../svg/SilverMedalIcon.svg";
import { ReactComponent as BronzeMedalIcon } from "../../svg/BronzeMedalIcon.svg";
import { ReactComponent as DeactivateIcon } from "../../svg/DeactivateIcon.svg";
import { ReactComponent as ExtendedIcon } from "../../svg/ExtendedIcon.svg";
import CustomTable from "../users/CustomTable";
import BasicCard from "../dashboard/BasicCard";
import { getUsersPagination } from "../../helper/externalCalls";
import { transformUserPackegeData } from "../../helper/transformData";
import { checkLoggedIn } from "../../helper/helper";
import { useHistory } from "react-router-dom";

const basicItems = [
    {
        title: "ALL PACKAGES",
        icon: <AllUsersIcon />,
        value: 80,
        color: "#4331D7",
    },
    {
        title: "GOLD PACKAGES",
        icon: <GoldMedalIcon />,
        value: 20,
        color: "#FFCC5B",
    },
    {
        title: "SILVER PACKAGES",
        icon: <SilverMedalIcon />,
        value: 30,
        color: "#ADADAD",
    },
    {
        title: "BRONZE PACKAGES",
        icon: <BronzeMedalIcon />,
        value: 30,
        color: "#CD7F32",
    },
];

const columns = [
    {
        field: "Name",
        headerName: "Name and surname",
    },
    {
        field: "Package",
        headerName: "Package",
        width: "7%",
    },
    {
        field: "Price",
        headerName: "Price",
        width: "7%",
    },
    {
        field: "Times",
        headerName: "Times",
        width: "7%",
    },
    {
        field: "Revenue",
        headerName: "Total revenue",
        flex: 1,
    },
    {
        field: "Button",
        headerName: "",
        flex: 1,
    },
];

const packageItems = [
    {
        id: "Gold",
        value: 0
    },
    {
        id: "Silver",
        value: 1
    },
    {
        id: "Bronze",
        value: 2
    },
];

const ROWS_PER_PAGE = 6;

export default function Packages() {
    const history = useHistory();
    const classes = useStyles();
    const [packageData, setPackageData] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [currentPackage, setCurrentPackage] = React.useState(0);

    checkLoggedIn(history);

    React.useEffect(() => {
        getUsersPagination(1, ROWS_PER_PAGE, localStorage.getItem("token")).then((data) => {
            data.list = transformUserPackegeData(data.list);
            setPackageData(data);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
    }, []);

    const basic = basicItems.map((el) => {
        return (
            <Grid item xs>
                <Paper className={classes.smallPaper}>
                    <BasicCard
                        title={el.title}
                        icon={el.icon}
                        value={el.value}
                        color={el.color}
                    />
                </Paper>
            </Grid>
        );
    });

    const handleButtonClick = () => {
        setShowAlert(true);
    };

    const handlePageChange = (_, newPage) => {
        getUsersPagination(newPage, ROWS_PER_PAGE, localStorage.getItem("token")).then((data) => {
            data.list = transformUserPackegeData(data.list);
            setPackageData(data);
        });
    };

    const alert = (
        <Modal
            open={showAlert}
            className={classes.dialog}
            onClose={() => {
                setShowAlert(false);
            }}
        >
            <Box className={classes.dialogContainer}>
                <Typography className={classes.dialogName}>Name Surname</Typography>
                <Box className={classes.rowDialog}>
                    <Box className={classes.columnDialog}>
                        <Typography className={classes.label}>Package</Typography>
                        <Select value={currentPackage} onChange={(_, target) => { setCurrentPackage(target.props.value)}}>
                            {packageItems.map((el) => {
                                return <MenuItem value={el.value}>{el.id}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <Button className={classes.button}>Change package</Button>
                </Box>
                <Box className={classes.rowDialog} style={{ justifyContent: "flex-start"}}>
                    <Box className={classes.columnDialog}>
                        <Typography className={classes.label}>Start date:</Typography>
                        <Typography>01.01.2000.</Typography>
                    </Box>
                    <Box className={classes.columnDialog} style={{ marginLeft: "10%"}}>
                        <Typography className={classes.label}>End date:</Typography>
                        <Typography>01.01.2000.</Typography>
                    </Box>
                </Box>
                <Box style={{ marginTop: "5%"}}>
                    <Button className={classes.deactivateButton}>
                        <DeactivateIcon />
                        <Typography style={{ marginLeft: "5%"}}>Deactivate package</Typography>
                    </Button>
                    <Button className={classes.extendButton}>
                        <ExtendedIcon />
                        <Typography style={{ marginLeft: "5%"}}>Extend package</Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );

    return (
        <Layout currentScreen={data.navigationItems.packages}>
            <Grid container item spacing={3} className={classes.row}>
                {basic}
            </Grid>
            <CustomTable
                columns={columns}
                buttonText="Manage"
                data={packageData}
                alert={alert}
                onButtonClick={handleButtonClick}
                handlePageChange={handlePageChange}
                imageWidth="15%"
            />
        </Layout>
    );
}

const getPackageData = (rowNum) => {
    const returnData = [];
    for (let i = 0; i < rowNum; i++) {
        returnData.push({
            Name: "Dummy name",
            Package: "Gold",
            Price: "150$",
            Times: 10 + i,
            Revenue: "1000$",
            Button: "Manage",
        });
    }
    return returnData;
};

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    smallPaper: {
        width: "16.5vw",
        height: "14vh",
        boxShadow: "0px 4px 30px rgba(182, 182, 182, 0.25)",
        borderRadius: 10,
        "&:hover": {
            background: "#F3F9FC",
        },
    },
    row: {
        marginTop: "0.5vh",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "1vh",
    },
    dialog: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dialogContainer: {
        height: "35vh",
        width: "30vw",
        background: "white",
        padding: theme.spacing(5),
        paddingTop: theme.spacing(3),
        boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.16)",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
    },
    dialogName: {
        fontWeight: 700,
        fontSize: "2.5vh",
        color: "black",
        marginBottom: theme.spacing(5)
    },
    rowDialog: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(3)
    },
    columnDialog: {
        display: "flex",
        flexDirection: "column",
    },
    button: {
        textTransform: "none",
        background: "#4068DF",
        color: "white",
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
        marginRight: "15%",
        height: "60%",
        width: "35%",
        "&:hover":{
            background: "#4068DF",
            color: "white"
        }
    },
    label: {
        fontSize: "2.3vh",
        color: "#4068DF",
        marginBottom: theme.spacing(1)
    },
    deactivateButton: {
        border: "1px solid #FF3939",
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
        textTransform: "none",
        color: "#FF3939",
        fontSize: "1.9vh",
        fontWeight: 700,
        width: "40%"
    },
    extendButton: {
        border: "1px solid #20B149",
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
        textTransform: "none",
        color: "#20B149",
        fontSize: "1.9vh",
        fontWeight: 700,
        width: "40%",
        marginLeft: theme.spacing(2)
    }
}));
