import axios from "axios";
import data from "./data.json";

async function login(username, password) {
    const options = {
        method: "POST",
        url: `${data.baseUrl}/authentication/login`,
        data: {
            username,
            password,
        },
    };

    const response = await axios(options);
    return response.data;
}

async function getDashboardData(token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/admin/dashboard-statistics`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function getUsersPagination(page, perPage, token, userRole="Roles", date="ascending") {
    let userRoleParameter = "";
    if(userRole !== "Roles"){
        userRoleParameter = `&UserRole=${userRole}`;
    }
    const options = {
        method: "GET",
        url: `${data.baseUrl}/admin/users/paging?PageNumber=${page}&PageSize=${perPage}&Date=${date}${userRoleParameter}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function getUserData(id, token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/user/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function customCall(url, method, token) {
    const options = {
        method,
        url: `${data.baseUrl}${url}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function getCompaniesForSalesman(id, token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/salesmancompany?SalesmanId=${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function getSalesmenForCompany(id, token){
    const options = {
        method: "GET",
        url: `${data.baseUrl}/salesmancompany?CompanyId=${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function uploadFiles(file, token) {
    const formData = new FormData();
    formData.append("File", file);
    const options = {
        method: "POST",
        url: `${data.baseUrl}/file`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formData,
    };
    return (await axios(options)).data;
}

async function getCompaniesPagination(page, perPage, token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/admin/companies/paging?PageNumber=${page}&PageSize=${perPage}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return (await axios(options)).data;
}

async function createCompany(companyData, token){
    const options = {
        method: "POST",
        url: `${data.baseUrl}/company`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {...companyData}
    };
    return (await axios(options)).data;
}

async function updateCompanyAdmin(companyId, companyData, token) {
    const options = {
        method: "PUT",
        url: `${data.baseUrl}/admin/company/${companyId}`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {...companyData}
    };
    return (await axios(options)).data;
}

async function getAllCategories(token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/companycategory`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return (await axios(options)).data;
}

async function deleteCompany(companyId, token){
    const options = {
        method: "DELETE",
        url: `${data.baseUrl}/company/${companyId}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function getAllSocialMedia(token) {
    const options = {
        method: "GET",
        url: `${data.baseUrl}/socialnetwork`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function createCompanyNetwork(networkData, token){
    const options = {
        method: "POST",
        url: `${data.baseUrl}/api/companynetworks`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: { ...networkData }
    };

    return (await axios(options)).data;
}

async function createCompanyFlow(companyData, token){
    const company = {
        companyName: companyData.CompanyName,
        companyLink: companyData.CompanyLink || "",
        about: companyData.About,
        locationId: companyData.LocationId,
        companyCategoryId: companyData.CompanyCategoryIds
    };
    const companyId = (await createCompany(company, token)).CompanyId;

    for(let socialNetworkId of companyData.CompanyLinks){
        await createCompanyNetwork({ url: companyData.CompanyLinks[socialNetworkId], companyId, socialNetworkId});
    }

    const headerImageId = companyData.cover !== './CoverImage.jpg' ? (await uploadFiles(companyData.cover, token)).FileId : 1412;
    const profileImageId = companyData.profile !== './ProfileImage.png' ? (await uploadFiles(companyData.profile, token)).FileId : 1414;
    const videoId = companyData.video ? (await uploadFiles(companyData.video, token)).FileId : 1413;

    if(!profileImageId && !headerImageId && !videoId){
        return;
    }

    const updateCompanyData = {
        companyId,
        profileImageId,
        headerImageId,
        videoId
    };

    return (await updateCompanyAdmin(companyId, updateCompanyData, token)).data;
}

async function deleteUser(id, token){
    const options = {
        method: "DELETE",
        url: `${data.baseUrl}/admin/delete-account?userId=${id}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return (await axios(options)).data;
}

async function deactivateAccount(email, token){
    const options = {
        method: "PUT",
        url: `${data.baseUrl}/admin/deactivate-account?Email=${email}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return (await axios(options)).data;
}

async function createSocialMedia(networkName, token){
    const options = {
        method: "POST",
        url: `${data.baseUrl}/socialnetwork`,
        data: {
            networkName
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function updateCompanyFlow(companyId, companyData, token){
    updateFiles(companyId, companyData.fileData, token);
    updateCompanyNetworks(companyId, companyData.companyNetworks, token);
    
    const lastIndex = companyData.location?.value?.terms?.length - 1;
    const locationId = companyData.location.label ? 
    (await postLocation({ city: companyData.location.value.terms[0].value, country: companyData.location.value.terms[lastIndex].value}, token)).LocationId 
    : companyData.location.LocationId;

    delete companyData.Location;

    const options = {
        method: "PUT",
        url: `${data.baseUrl}/company/new-update/${companyId}`,
        data: {
            ...companyData,
            locationId
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function updateFiles(companyId, fileData, token){
    const headerImageId = !fileData.cover.FileId ? (await uploadFiles(fileData.cover, token)).FileId : fileData.cover.FileId;
    const profileImageId = !fileData.profile.FileId ? (await uploadFiles(fileData.profile, token)).FileId : fileData.profile.FileId;
    const videoId = !fileData.video.FileId ? (await uploadFiles(fileData.video, token)).FileId : fileData.video.FileId;

    const updateCompanyData = {
        companyId,
        profileImageId,
        headerImageId,
        videoId
    };

    return (await updateCompanyAdmin(companyId, updateCompanyData, token)).data;
}

async function updateCompanyNetworks(companyId, companyNetworks, token){
    const socialMediaLinks = await getSocialMediaLinks(companyId, token);

    for(var networkId in companyNetworks){
        if(networkId in socialMediaLinks){
            putCompanyNetwork(companyId, { socialNetworkId: networkId, url: companyNetworks[networkId] })
        }else{
            createCompanyNetwork({ socialNetworkId: networkId, url: companyNetworks[networkId], companyId }, token);
        }
    }
}

async function putCompanyNetwork(companyId, companyNetwork, token){
    const options = {
        method: "PUT",
        url: `${data.baseUrl}/api/companyNetwork/${companyId}`,
        data: {
            ...companyNetwork
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function getLocationByCity(city, token){
    const options = {
        method: "GET",
        url: `${data.baseUrl}/location?City=${city}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function postLocation(location, token){
    const cities = await getLocationByCity(location.city, token);

    if(cities && cities.length !== 0){
        return cities[0];
    }

    const options = {
        method: "POST",
        url: `${data.baseUrl}/location`,
        data: {
            ...location
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function getSocialMediaLinks(companyId, token){
    const options = {
        method: "GET",
        url: `${data.baseUrl}/api/companynetworks/${companyId}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return (await axios(options)).data;
}

async function googlePlacesAutocomplete(string){
    const options = {
        method: "GET",
        url: `${data.googleAutocompleteUrl}?input=${string}&types=(cities)&key=${data.mapApiKey}`
    };

    return (await axios(options)).data;
}

export {
    login,
    getDashboardData,
    getUsersPagination,
    getUserData,
    customCall,
    getCompaniesForSalesman,
    uploadFiles,
    getCompaniesPagination,
    getAllCategories,
    getSalesmenForCompany,
    postLocation,
    createCompany,
    updateCompanyAdmin,
    createCompanyFlow,
    deleteCompany,
    getAllSocialMedia,
    deleteUser,
    deactivateAccount,
    createSocialMedia,
    updateCompanyFlow as updateCompany,
    getSocialMediaLinks,
    googlePlacesAutocomplete
};
