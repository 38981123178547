import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as GrowthIcon } from "../../svg/GrowthIcon.svg";
import { ReactComponent as FallIcon } from "../../svg/FallIcon.svg";
import {
    Area,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

export default function CustomGraph(props) {
    const classes = useStyles();

    return (
        <Box {...props} className={classes.root}>
            <Box className={classes.row}>
                <Typography className={classes.title}>{props.name}</Typography>
            </Box>
            <Box className={classes.row} style={{ marginBottom: "2%"}}>
                {props.percentage >= 0 ? <GrowthIcon /> : <FallIcon />}
                <Typography className={classes.smallText}>{`${Math.abs(
                    props.percentage
                )}% ${props.percentage >= 0 ? "mehr" : "weniger"}`}</Typography>
                <Typography className={classes.smallText}>
                    im {props.lastMonth}
                </Typography>
            </Box>
            <ResponsiveContainer>
                <AreaChart data={props.data}>
                    <defs>
                        <linearGradient id={`fillColor-${props.color}`} x1="0" y1="0" x2="0" y2="1">
                            <stop offset={"5%"} stopColor={props.color} stopOpacity={0.8} />
                            <stop offset={"95%"} stopColor={props.color} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"3 3"} />
                    <XAxis dataKey={"day"} />
                    <YAxis />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke={props.color}
                        fill={`url(#fillColor-${props.color})`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "40vh",
        width: "90%",
        flexDirection: "column",
        background: "white",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        borderRadius: 12,
        boxShadow: "0px 20px 27px rgba(228, 228, 228, 0.05)",
        paddingLeft: "1%",
        paddingRight: "1%"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginLeft: "1%",
    },
    smallText: {
        fontWeight: 600,
        color: "#565656",
        marginLeft: "0.5%",
    },
    title: {
        fontWeight: 700,
        fontSize: "1.8vh",
        color: "#2D2D2D",
        marginTop: "1%",
    },
}));
