import React from "react";
import { Container, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function BasicInfo(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root} {...props}>
            {!props.showNone && (
                <>
                    <Typography className={classes.title}>
                        {props.title}:
                    </Typography>
                    <Typography className={classes.value}>
                        {props.value}
                    </Typography>
                </>
            )}
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-start",
    },
    title: {
        fontSize: "2.3vh",
        color: "#4068DF",
        margin: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            fontSize: 16
        }
    },
    value: {
        fontSize: "2.3vh",
        color: "#2D2D2D",
        margin: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            fontSize: 16
        }
    },
}));
