
function checkLoggedIn(history){
    const token = localStorage.getItem("token")
    if(!token || token === ""){
        history.push("/");
    }
}

export {
    checkLoggedIn
};