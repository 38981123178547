import React from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Input,
    InputAdornment,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import { ReactComponent as EyeCrossed } from "../../svg/eye-crossed.svg";

import { login } from "../../helper/externalCalls";

export default function LoginForm() {
    const classes = useStyles();
    const history = useHistory();

    const [usernameActive, setUsernameActive] = React.useState(false);
    const [passwordActive, setPasswordActive] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorShown, setIsErrorShown] = React.useState(false);

    const handleClick = () => {
        setIsErrorShown(false);
        setIsLoading(true);
        login(username, password)
            .then((data) => {
                localStorage.setItem("token", data.Token);
                setIsLoading(false);
                history.push("/dashboard");
            })
            .catch((err) => {
                setIsLoading(false);
                setIsErrorShown(true);
            });
    };

    const handleFocus = (isUsername) => {
        if (isUsername) {
            setUsernameActive(true);
            setPasswordActive(false);
        } else {
            setUsernameActive(false);
            setPasswordActive(true);
        }
    };

    const onKeyDown = (event) => {
        if(event.key === 'Enter'){
            document.getElementById("submitLogin").click();
        }
    };

    return (
        <Box className={classes.root} onKeyDown={onKeyDown}>
            <Box className={classes.circle}>
                <img className={classes.image} src="./logo.png" />
            </Box>
            <Typography className={classes.title}>MAGU</Typography>
            <Box className={classes.inputForm}>
                <Typography
                    className={`${classes.inputLabel} ${
                        usernameActive && classes.activeLabel
                    }`}
                >
                    Benutzername
                </Typography>
                <Input
                    placeholder="benutzername"
                    className={`${classes.inputField} ${
                        usernameActive && classes.activeField
                    }`}
                    disableUnderline
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    onFocus={() => {
                        handleFocus(true);
                    }}
                    style={username === "" ? { color: "#BCBCBC"} : { color: "black"} }
                />
                {errorShown && <Typography className={classes.error}>Invalid username or password!</Typography>}

                <Typography
                    className={`${classes.inputLabel} ${
                        passwordActive && classes.activeLabel
                    }`}
                >
                    Passwort
                </Typography>
                <Input
                    placeholder="passwort"
                    className={`${classes.inputField} ${
                        passwordActive && classes.activeField
                    }`}
                    disableUnderline
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    onFocus={() => {
                        handleFocus(false);
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                            >
                                <EyeCrossed />
                            </IconButton>
                        </InputAdornment>
                    }
                    style={password === "" ? { color: "#BCBCBC"} : { color: "black"} }
                />
                <Button id="submitLogin" className={classes.button} onClick={handleClick}>
                    Einloggen
                </Button>
                {isLoading && (
                    <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        color={"#4331D7"}
                        style={{ marginTop: "5%" }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "20vh",
        left: "32vw",
        bottom: "15vh",
        right: "32vw",
        background: "white",
        zIndex: 10,
        borderRadius: 10,
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        [theme.breakpoints.down("xs")]: {
            left: 5,
            right: 5
        }
    },
    inputForm: {
        marginTop: "5%",
        height: "65%",
        width: "100%",
    },
    inputField: {
        width: "100%",
        height: "15%",
        background: "#EDEDED",
        radius: 8,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    activeField: {
        border: "1px solid #3D9DE7",
    },
    inputLabel: {
        fontWeight: 700,
        fontSize: "2.1vh",
        textDecoration: "none",
        marginBottom: theme.spacing(2),
    },
    activeLabel: {
        color: "#4331D7",
    },
    button: {
        width: "100%",
        background: "#4331D7",
        color: "white",
        marginTop: "7%",
        height: "15%",
        "&:hover": {
            background: "#4331D7",
        },
    },
    circle: {
        borderRadius: "50%",
        width: "10vw",
        height: "10vw",
        background: "white",
        marginTop: "-5vw",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            width: "40vw",
            height: "40vw"
        }
    },
    image: {
        margin: "auto",
    },
    title: {
        fontWeight: 800,
        fontSize: "3.7vh",
        textAlign: "center",
    },
    error: {
        color: "red",
        fontSize: "1.3vh",
        marginTop: theme.spacing(-1)
    },
    placeholderColor: {
        "&::placeholder": {
            color: "#BCBCBC"
        }
    }
}));
