import React from "react";
import { Button, Typography, Box, Modal, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";
import Scrollbars from "react-custom-scrollbars-2";
import BasicInfo from "./BasicInfo";
import {
    getUserData,
    getCompaniesForSalesman,
    deleteUser,
    deactivateAccount,
} from "../../helper/externalCalls";

import { ReactComponent as DeactivateIcon } from "../../svg/DeactivateIcon.svg";
import { ReactComponent as DeactivateUserIcon } from "../../svg/DeactivateUserIcon.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { checkLoggedIn } from "../../helper/helper";

const options = [
    {
        id: 0,
        text: "Paket deaktivieren möchten",
        url: "/admin/deactivate-package",
        method: "PUT",
    },
    {
        id: 1,
        text: "Konto deaktivieren möchten",
        url: "/admin/deactivate-account",
        method: "PUT",
    },
    {
        id: 2,
        text: "Konto löschen möchten",
        url: "/admin/delete-user",
        method: "DELETE",
    },
];
const DEACTIVATE_PACKAGE = 0;
const DEACTIVATE_ACCOUNT = 1;
const DELETE_ACCOUNT = 2;

export default function UserInfo(props) {
    const location = useLocation();
    const history = useHistory();

    const classes = useStyles();
    const [user, setUser] = React.useState({});
    const [userCompanies, setUserCompanies] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [option, setOption] = React.useState({});

    checkLoggedIn(history);

    React.useEffect(() => {
        const userId = location.state.userId;
        getUserData(userId, localStorage.getItem("token")).then((data) => {
            setUser(data);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
        getCompaniesForSalesman(userId, localStorage.getItem("token"))
            .then((data) => {
                const companyNames = data.map(
                    (entry) => entry.Company.CompanyName
                );
                setUserCompanies(companyNames);
            }).catch((err) => {
                if(err.response.status === 401){
                    history.push("/");
                }
            });
    }, []);

    const userId = location.state.userId;

    const handleDeleteUser = () => {
        deleteUser(userId, localStorage.getItem("token"))
            .then((data) => {
                console.log(data);
                history.push("/users");
            })
            .catch((err) => console.log(err));
    };

    const handleDeactivateAccount = () => {
        deactivateAccount(user.Email, localStorage.getItem("token"))
            .then((data) => {
                console.log(data);
                history.push("/users");
            })
            .catch((err) => console.log(err));
    };

    const handleOnClick = () => {
        console.log(option);
        switch (option.id) {
            case DEACTIVATE_ACCOUNT:
                handleDeactivateAccount();
                break;
            case DELETE_ACCOUNT:
                handleDeleteUser();
                break;
            case DEACTIVATE_PACKAGE:
                console.log("Not yet implemented");
                break;
            default:
                console.log("Invalid option");
        }
    };

    const customButton = (title, icon, option) => {
        return (
            <Button
                className={classes.customButton}
                onClick={() => {
                    setOption(option);
                    setOpenDialog(true);
                }}
            >
                {icon}
                <Hidden xsDown>
                    <Typography className={classes.customButtonText}>
                        {title}
                    </Typography>
                </Hidden>
            </Button>
        );
    };
console.log(user)
    return (
        <Layout currentScreen={data.navigationItems.users}>
            <Scrollbars
                color="#fff"
                style={{ width: "100%", height: "90vh" }}
                renderThumbVertical={({ style, ...props }) => (
                    <div
                        {...props}
                        style={{
                            ...style,
                            backgroundColor: "#4068DF",
                            width: "5px",
                            borderRadius: "10px",
                        }}
                    />
                )}
            >
                <Box className={classes.root}>
                    <Typography
                        className={classes.title}
                        style={{ marginLeft: "2%", marginTop: "2%", marginBottom: 0 }}
                    >
                        {user.FirstName + " " + user.LastName}
                    </Typography>
                    {user?.UserType === "User" && <Typography
                        style={{ marginLeft: "2%" }}
                    >
                        {user.Email}
                    </Typography>}
                    {user?.UserType === "Salesman" && (
                        <>
                            <Box className={classes.mainData}>
                                <Hidden xsDown>
                                    <Box className={classes.imageContainer}>
                                        <img
                                            src={user.ProfilePictureUrl}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        />
                                    </Box>
                                </Hidden>
                                <Box className={classes.mainDataContainer}>
                                    <Box className={classes.row}>
                                        <BasicInfo
                                            title="Die Rolle"
                                            value={user.UserType}
                                        />
                                        <BasicInfo
                                            title="E-Mail"
                                            value={user.Email}
                                        />
                                        <BasicInfo
                                            title="Vorname und Nachname"
                                            value={
                                                user.FirstName +
                                                " " +
                                                user.LastName
                                            }
                                        />
                                    </Box>
                                    <Box className={classes.row}>
                                        <BasicInfo
                                            title="Nummer"
                                            value={user.PhoneNumber || "/"}
                                        />
                                        <BasicInfo
                                            title="Standort"
                                            value={
                                                user.Location?.City +
                                                ", " +
                                                user.Location?.Country
                                            }
                                        />
                                        <BasicInfo showNone />
                                    </Box>
                                    <Box className={classes.row}>
                                        <BasicInfo
                                            title="Unternehmen"
                                            value={(userCompanies || []).join(
                                                ", "
                                            )}
                                        />
                                        <BasicInfo showNone />
                                        <BasicInfo showNone />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.pointsContainer}>
                                <Box className={classes.column}>
                                    <Typography
                                        className={classes.title}
                                        style={{ marginLeft: "3%" }}
                                    >
                                        Points
                                    </Typography>
                                    <Box
                                        className={classes.row}
                                        style={{
                                            marginTop: "1%",
                                            marginLeft: "2%",
                                        }}
                                    >
                                        <BasicInfo
                                            title="Aktuelle Punkte"
                                            value={user.Points}
                                            style={{ marginLeft: "0.5%" }}
                                        />
                                        <BasicInfo showNone />
                                        <BasicInfo showNone />
                                        <BasicInfo showNone />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                   {user?.UserType === "Salesman" && <Box className={classes.packagesContainer}>
                        <Box className={classes.column}>
                            <Typography
                                className={classes.title}
                                style={{ marginLeft: "2.5%" }}
                            >
                                Pakete
                            </Typography>
                            <Box
                                className={classes.row}
                                style={{
                                    marginTop: "2%",
                                    marginLeft: "2%",
                                    marginRight: "-2%",
                                }}
                            >
                                <BasicInfo
                                    title="Aktuelles Paket"
                                    value={user.currentPackage || "-"}
                                />
                                <BasicInfo showNone />
                                <BasicInfo showNone />
                                {customButton(
                                    "Paket deaktivieren",
                                    <DeactivateIcon />,
                                    options[DEACTIVATE_PACKAGE]
                                )}
                            </Box>
                            <Box
                                className={classes.row}
                                style={{ marginTop: "4%", marginLeft: "2%" }}
                            >
                                <BasicInfo title="Paket" value="Gold" />
                                <BasicInfo
                                    title="Gekauft"
                                    value={user.goldPurchased || "-"}
                                />
                                <BasicInfo showNone />
                                <BasicInfo showNone />
                            </Box>
                            <Box
                                className={classes.row}
                                style={{ marginTop: "1%", marginLeft: "2%" }}
                            >
                                <BasicInfo title="Paket" value="Silber" />
                                <BasicInfo
                                    title="Gekauft"
                                    value={user.silverPurchased || "-"}
                                />
                                <BasicInfo showNone />
                                <BasicInfo showNone />
                            </Box>
                            <Box
                                className={classes.row}
                                style={{ marginTop: "1%", marginLeft: "2%" }}
                            >
                                <BasicInfo title="Paket" value="Bronze" />
                                <BasicInfo
                                    title="Gekauft"
                                    value={user.bronzePurchased || "-"}
                                />
                                <BasicInfo showNone />
                                <BasicInfo showNone />
                            </Box>
                        </Box>
                    </Box>}
                    <Box className={classes.accountContainer}>
                        <Box className={classes.column}>
                            <Typography
                                className={classes.title}
                                style={{ marginLeft: "2%" }}
                            >
                                Konto verwalten
                            </Typography>
                            <Box
                                className={classes.row}
                                style={{
                                    height: "10vh",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography style={{ marginLeft: "2%" }}>
                                    Konto für Benutzer deaktivieren
                                </Typography>
                                {customButton(
                                    "Konto deaktivieren",
                                    <DeactivateIcon />,
                                    options[DEACTIVATE_ACCOUNT]
                                )}
                            </Box>
                            <Box
                                className={classes.row}
                                style={{
                                    height: "10vh",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography style={{ marginLeft: "2%" }}>
                                    Konto für Benutzer löschen
                                </Typography>
                                {customButton(
                                    "Konto löschen",
                                    <DeactivateUserIcon />,
                                    options[DELETE_ACCOUNT]
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Scrollbars>
            <Modal
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
                className={classes.dialog}
            >
                <Box className={classes.dialogContainer}>
                    <Typography className={classes.dialogTitle}>
                        {`Sind Sie sicher, dass Sie dieses ${option.text}?`}
                    </Typography>
                    <Button
                        className={classes.button}
                        style={{ background: "#4331D7", color: "white" }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Nein, ich bin nicht
                    </Button>
                    <Button
                        className={classes.button}
                        style={{
                            background: "white",
                            color: "#151920",
                            border: "1px solid rgba(86, 103, 137, 0.26)",
                        }}
                        onClick={handleOnClick}
                    >
                        Ja,{" "}
                        {option.method === "DELETE"
                            ? "löschen"
                            : "deaktivieren"}
                    </Button>
                </Box>
            </Modal>
        </Layout>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    mainData: {
        display: "flex",
        width: "99%",
        height: "50vh",
        flexDirection: "row",
        margin: theme.spacing(1),
    },
    imageContainer: {
        height: "100%",
        width: "25%",
        borderRadius: 10,
        filter: "drop-shadow(0px 4px 20px rgba(190, 190, 190, 0.06))",
        [theme.breakpoints.down("xs")]: {
            height: "50%",
            width: "50%",
        },
    },
    mainDataContainer: {
        width: "50%",
        height: "100%",
        borderRadius: 10,
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
    },
    pointsContainer: {
        display: "flex",
        flexDirection: "column",
        width: "97%",
        height: "20vh",
        margin: theme.spacing(1),
        boxShadow: " 0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
    },
    packagesContainer: {
        display: "flex",
        flexDirection: "column",
        width: "97%",
        height: "70vh",
        margin: theme.spacing(1),
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
    },
    accountContainer: {
        display: "flex",
        flexDirection: "column",
        width: "99%",
        height: "30vh",
        boxShadow: " 0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
        margin: theme.spacing(1),
    },
    title: {
        fontWeight: 700,
        fontSize: "2.5vh",
        color: "#2D2D2D",
        margin: theme.spacing(2),
    },
    row: {
        display: "flex",
        flexDirection: "row",
        height: "30%",
        width: "100%",
        alignItems: "center",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    dialog: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        width: "100%",
        height: "25%",
        borderRadius: 8,
        marginTop: theme.spacing(2),
        textTransform: "none",
        fontWeight: 600,
    },
    dialogContainer: {
        height: "30vh",
        width: "35vw",
        background: "white",
        padding: theme.spacing(5),
        paddingTop: theme.spacing(3),
        boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.16)",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: "2.5vh",
        color: "black",
        marginBottom: "5%",
        marginLeft: "auto",
        marginRight: "auto",
        margin: theme.spacing(3),
    },
    customButton: {
        border: "1px solid #FF3939",
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        borderRadius: 10,
        marginRight: "2%",
        height: "50%",
        width: "20%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    customButtonText: {
        fontSize: "1.7vh",
        fontWeigth: 700,
        color: "#FF3939",
        marginLeft: "5%",
    },
}));
