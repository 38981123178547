import React from "react";
import {
    Box,
    Typography,
    Button,
    Modal,
    CircularProgress,
    Hidden
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import data from "../../helper/data.json";
import CustomTable from "../users/CustomTable";
import ManageCompany from "./ManageCompany";
import { ReactComponent as PlusIcon } from "../../svg/PlusIcon.svg";
import AddCompany from "./AddCompany";
import {
    getCompaniesPagination,
    customCall,
    createCompanyFlow,
    postLocation
} from "../../helper/externalCalls";
import { transformCompanyData } from "../../helper/transformData";
import { checkLoggedIn } from "../../helper/helper";
import { useHistory } from "react-router-dom";

const columns = [
    {
        field: "Name",
        headerName: "Name des Unternehmes",
    },
    {
        field: "Category",
        headerName: "Kategorie",
    },
    {
        field: "Salesmen",
        headerName: "Anzahl der Vetriebsmitarbeiter",
        mobileHidden: true
    },
    {
        field: "Button",
        headerName: "",
        flex: 1,
    },
];

const company = {
    cover: "./CoverImage.jpg",
    profile: "./ProfileImage.png",
};

const ROWS_PER_PAGE = 6;

export default function Companies() {
    const classes = useStyles();
    const history = useHistory();

    const [numberOfCategories, setNumberOfCategories] = React.useState(0);
    const [numberOfCompanies, setNumberOfCompanies] = React.useState(0);
    const [companies, setCompanies] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [showAddCompany, setShowAddCompany] = React.useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [isAddingCompany, setIsAddingCompany] = React.useState(false);

    const newCompany = React.useRef(company);

    checkLoggedIn(history);

    React.useEffect(() => {
        getCompaniesPagination(
            page,
            ROWS_PER_PAGE,
            localStorage.getItem("token")
        ).then((data) => {
            if(isAddingCompany){
                setPage(data.TotalPages);
                setIsAddingCompany(false);
            }
            data.list = transformCompanyData(data.list);
            setCompanies(data);
            setNumberOfCompanies(data.TotalCount);
            setIsLoading(false);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
        customCall(
            "/companycategory",
            "GET",
            localStorage.getItem("token")
        ).then((data) => {
            setNumberOfCategories(data.length);
        }).catch((err) => {
            if(err.response.status === 401){
                history.push("/");
            }
        });
    }, [page, history, isAddingCompany]);

    const handleButtonClick = (id) => {
        setSelectedCompanyId(id);
        setShowModal(true);
    };

    const handleCloseClick = () => {
        setShowModal(false);
    };

    const handleCloseAddCompanyClick = () => {
        newCompany.current = company;
        setShowAddCompany(false);
    };
    
    const handlePageChange = (_, newPage) => {
        setPage(newPage);
    };

    const handlePropertyChange = (propertyName, value) => {
        newCompany.current[propertyName] = value;
    };

    return (
        <Layout currentScreen={data.navigationItems.companies}>
            {isLoading ? (
                <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    height="100vh"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box>
                        <Box className={classes.basicInfo}>
                            <Box className={classes.infoContainer}>
                                <Typography className={classes.infoText}>
                                    Anzahl der Kategorien: {numberOfCategories}
                                </Typography>
                                <Typography className={classes.infoText}>
                                    Anzahl der Unternehmen: {numberOfCompanies}
                                </Typography>
                            </Box>
                            <Button
                                className={classes.button}
                                onClick={() => setShowAddCompany(true)}
                            >
                                <PlusIcon />
                                <Hidden xsDown>
                                    <Typography style={{ marginLeft: "5%", fontSize: "1.5vh" }}>
                                        Unternehmen hinzufügen
                                    </Typography>
                                </Hidden>
                            </Button>
                        </Box>
                        <CustomTable
                            columns={columns}
                            data={companies}
                            buttonText="Manage"
                            rowsPerPage={7}
                            onButtonClick={handleButtonClick}
                            handlePageChange={handlePageChange}
                            imageWidth="20%"
                        />
                    </Box>
                    <Modal
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        open={showModal}
                        onClose={() => setShowModal(false)}
                    >
                        <ManageCompany
                            handleCloseClick={handleCloseClick}
                            companyId={selectedCompanyId}
                        />
                    </Modal>
                    <Modal
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        open={showAddCompany}
                        onClose={() => handleCloseAddCompanyClick()}
                    >
                        <Box className={classes.addCompanyContainer}>
                            <AddCompany
                                company={newCompany.current}
                                handleCloseClick={handleCloseAddCompanyClick}
                                handlePropertyChange={handlePropertyChange}
                            />
                            <Box className={classes.stickyFooter}>
                                <Button
                                    className={classes.footerButton}
                                    onClick={async () => {      
                                                              
                                        if(newCompany.current.Location){
                                            const lastIndex = newCompany.current.Location.value.terms.length - 1;
                                            const location = {
                                                city: newCompany.current.Location.value.terms[0].value,
                                                country: newCompany.current.Location.value.terms[lastIndex].value,
                                            };

                                            delete newCompany.current.Location;
                                            newCompany.current.LocationId = (await postLocation(location, localStorage.getItem("token"))).LocationId;
                                        }
                                        
                                        createCompanyFlow(
                                            newCompany.current,
                                            localStorage.getItem("token")
                                        )
                                            .then((data) => {
                                                newCompany.current = company;
                                                
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                            setShowAddCompany(false);
                                            setIsAddingCompany(true);
                                            
                                    }}
                                >
                                    Hinzufügen
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </>
            )}
        </Layout>
    );
}

//add new styles here
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    basicInfo: {
        width: "90%",
        height: "10vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    infoContainer: {
        marginRight: "auto",
        display: "flex",
        flexDirection: "row",
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "70%"
        }
    },
    infoText: {
        marginRight: "15%",
        fontSize: "1.9vh",
        color: "#2D2D2D",
        [theme.breakpoints.down("xs")]: {
            fontSize: 14
        }
    },
    button: {
        marginLeft: "auto",
        width: "25%",
        height: "60%",
        textTransform: "none",
        background: "#3D9DE7",
        color: "white",
        borderRadius: 10,
        fontSize: "1.5vh",
        "&:hover": {
            background: "#3D9DE7",
        },
        [theme.breakpoints.down("xs")]: {
            width: "10%"
        }
    },
    addCompanyContainer: {
        height: "90vh",
        width: "90vw",
        background: "white",
        position: "relative",
    },
    stickyFooter: {
        position: "absolute",
        bottom: "0%",
        top: "90%",
        left: 0,
        right: 0,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    footerButton: {
        width: "25%",
        height: "70%",
        textTransform: "none",
        color: "white",
        background: "#4068DF",
        borderRadius: 10,
        boxShadow: "0px 4px 20px rgba(190, 190, 190, 0.06)",
        fontSize: "2.1vh",
        "&:hover": {
            background: "#4068DF",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 16,
            width: "35%"
        }
    },
}));
